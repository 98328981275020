import React, { useState, useEffect } from "react";
import AppliedJob from "./AppliedJob";
import "./ViewPostingsPageAdmin.css"; // Make sure to link your CSS file here
import Header from "./Header";
import Footer from "./Footer";

const ViewPostingsPageAdmin = () => {
  const [jobPostings, setJobPostings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userID = sessionStorage.getItem("userID");

  useEffect(() => {
    const fetchJobPostings = async () => {
      try {
        let url = `http://nxp7046.uta.cloud/php_api/getJobsAPI.php`;
        if (searchTerm) {
          url += `&searchQuery=${encodeURIComponent(searchTerm)}`;
        }
        const response = await fetch(url);
        console.log(response);
        const data = await response.json();
        setJobPostings(data);
      } catch (error) {
        console.error("Error fetching job postings:", error);
      }
    };
    // fetchAppliedJobs(userID);
    fetchJobPostings();
  }, [searchTerm]);
  // Fetch applied jobs data when the component mounts

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Header name="Academia Dashboard"></Header>
      <div className="view-job-postings">
        <h1>Job Postings in Academia</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search jobs by title..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="job-postings">
          {jobPostings.map((job) => (
            <AppliedJob
              key={job.JobID}
              title={job.JobTitle}
              desc={job.Description}
              pos={job.Position}
              status={job.Status}
            />
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default ViewPostingsPageAdmin;
