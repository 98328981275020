import "./Login.css";

import { useState } from "react";

import validator from "validator";

import { BrowserRouter, Link, useNavigate } from "react-router-dom";

import axios from "axios";

const Login = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,

        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage("Strong Password");
    } else {
      setErrorMessage("Weak Password");
    }
  };

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      Email: email,

      Password: password,
    };
    axios

      .post("http://nxp7046.uta.cloud/php_api/loginAPI.php", {
        Email: email,

        Password: password,
      })

      .then((response) => {
        console.log(response.data);
        // console.log("isLoggedIn  : " + response.data.isLoggedIn);
        // console.log("stringify : " + JSON.stringify(response));
        if (response.data.isLoggedIn === true) {
          // sessionStorage.setItem("loggedIn", true);

          // sessionStorage.setItem(
          //   "userData",

          //   JSON.stringify(response.data.data)
          // );
          sessionStorage.setItem("userID", response.data.userID);
          sessionStorage.setItem("isLoggedIn", response.data.isLoggedIn);
          sessionStorage.setItem("userRole", response.data.userRole);

          if (response.data.userRole === "Candidate") {
            navigate("/candidatedash");
          } else if (response.data.userRole === "university") {
            navigate("/academiadash");
          } else if (response.data.userRole === "DEI_Officer") {
            navigate("/deidash");
          } else if (response.data.userRole === "Recruiter") {
            navigate("/recruiterdash");
          } else if (response.data.userRole === "Admin") {
            navigate("/admindash");
          }
        } else {
          console.log(response.data.error);
          alert(response.data.error);
          setError(response.data.message);
        }
      })

      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="primary-back">
        <div className="navbar">
          <div className="nav-left">
            <h2>URM APPLICATION</h2>

            <img
              className="pfp"
              src={require("../../../components/images/urm.png")}
            ></img>
          </div>

          <div className="nav-right">
            {/* <a className='navtext' href='#'>Profile</a> */}

            <Link className="navtext" to="/home">
              Home
            </Link>

            <Link className="navtext" to="/about">
              About
            </Link>

            <Link className="navtext" to="/contactus">
              Contact Us
            </Link>

            <Link className="navtext" to="/services">
              Services
            </Link>

            <a className="navtext" href="https://axm0558.uta.cloud/">
              Blog
            </a>

            <Link className="navtext" to="/signin">
              Sign Up
            </Link>
          </div>
        </div>

        <div className="sec-bar">
          <h1>URM Application</h1>
        </div>

        <div className="partdiv">
          <div className="partbar"></div>
        </div>

        <div className="welcometext">
          <h3>Welcome Back !</h3>
        </div>

        <div className="midinpanel">
          <div className="candidatesign">
            <div className="registerhead">
              <h4>Please enter your credentials</h4>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="top2">
                <input
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="top3">
                <input
                  id="password"
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

                {/* <div className="eyediv">
                  <button className="eyebutton">👁️‍🗨️</button>
                  onClick={passwordClick}
                </div> */}
              </div>

              {errorMessage === "" ? null : (
                <div className="passwordtextdiv">
                  <p className="passwordtext">{errorMessage}</p>
                </div>
              )}

              <div className="forgottext">
                <Link to={"/forgotpass"}>Forgot Your Password ?</Link>
              </div>

              <div className="top5">
                <button type="submit" className="registerbutton">
                  Login
                </button>
              </div>
            </form>

            <div className="newregdiv">
              <p style={{ marginRight: "5px" }}>New Here ?</p>

              <Link to={"/signin"}>Register</Link>
            </div>
          </div>
        </div>

        <div className="footer">
          <p className="footertext">Copyright &copy; All rights are reserved</p>
        </div>
      </div>
    </>
  );
};

export default Login;
