import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Signin from "./js/screens/onboarding/signin/Signin";
import Login from "./js/screens/onboarding/login/Login";
import Forgotpass from "./js/screens/onboarding/forgotpass/Forgotpass";
import Contactus from "./js/screens/onboarding/contactus/Contactus";
import CandidateDashboard from "./js/screens/onboarding/CandidateDashboard";
import AdminDashboard from "./js/screens/onboarding/AdminDashboard";
import RecruiterDashboard from "./js/screens/onboarding/RecruiterDashboard";
import DEIOfficerDashboard from "./js/screens/onboarding/DEIOfficerDashboard";
import AcademiaDashboard from "./js/screens/onboarding/AcademiaDashboard";
import Home from "./js/screens/onboarding/Home";
import About from "./js/screens/onboarding/About";
import Services from "./js/screens/onboarding/Services";
import Header from "./js/screens/onboarding/Header";
import Header2 from "./js/screens/onboarding/Header2";
import CandidateProfile from "./js/screens/onboarding/CandidateProfile";
import UniversityProfile from "./js/screens/onboarding/UniversityProfile";
import DEIOfficerProfile from "./js/screens/onboarding/DEIOfficerProfile";
import RecruiterProfile from "./js/screens/onboarding/RecruiterProfile";
import Jobs from "./js/screens/onboarding/Jobs";
import JobListing from "./js/screens/onboarding/JobListing";
import ApplyJobs from "./js/screens/onboarding/ApplyJobs";
import AppliedJobsPage from "./js/screens/onboarding/AppliedJobsPage";
import ViewPostingsPage from "./js/screens/onboarding/ViewPostingsPage";
import CreateJobPage from "./js/screens/onboarding/CreateJobPage";
import ApplicationsPage from "./js/screens/onboarding/ApplicationsPage";
import UpdateProfilePageUni from "./js/screens/onboarding/UpdateProfilePageUni";
import ViewCandidatesPage from "./js/screens/onboarding/ViewCandidatePage";
import UpdateRecruiterProfilePage from "./js/screens/onboarding/UpdateRecruiterProfilePage";
import UpdateCandidateProfilePage from "./js/screens/onboarding/UpdateCandidateProfilePage";
import UpdateDEIProfilePage from "./js/screens/onboarding/UpdateDEIProfile";
import ApproveProfilesPage from "./js/screens/onboarding/ApproveProfilesPage";
import ViewPostingsPageAdmin from "./js/screens/onboarding/ViewPostingsPageAdmin";
import ApplicationsPageAdmin from "./js/screens/onboarding/ApplicationsPageAdmin";
import ViewIssues from "./js/screens/onboarding/ViewIssues.jsx";
import DEIInitiativesPage from "./js/screens/onboarding/DEIInitiativesPage";

// import ProtectedRoute from "./js/screens/ProtectedRoute.jsx";
function App() {
  // const isAuthenticated = true;
  return (
    <div className="primary">
      {/* <Signin></Signin> */}
      {/* <Login></Login> */}
      {/* <Forgotpass></Forgotpass> */}
      {/* <Contactus></Contactus> */}
      {/* <AdminDashboard name='Admin Dashboard'></AdminDashboard> */}
      {/* <RecruiterDashboard name='Recruiter Dashboard'></RecruiterDashboard> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Signin></Signin>} />
          <Route path="/signin" element={<Signin></Signin>}></Route>
          <Route path="/home" element={<Home></Home>}></Route>
          <Route path="/about" element={<About></About>} />
          <Route path="/services" element={<Services></Services>} />
          <Route path="/contactus" element={<Contactus></Contactus>} />
          <Route path="/login" element={<Login></Login>} />
          <Route path="/forgotpass" element={<Forgotpass></Forgotpass>}></Route>
          <Route path="/viewissues" element={<ViewIssues></ViewIssues>}></Route>

          <Route
            path="/candidatedash"
            element={<CandidateDashboard></CandidateDashboard>}
          />
          <Route
            path="/recruiterdash"
            element={<RecruiterDashboard></RecruiterDashboard>}
          />
          <Route
            path="/deidash"
            element={<DEIOfficerDashboard></DEIOfficerDashboard>}
          />
          <Route
            path="/academiadash"
            element={<AcademiaDashboard></AcademiaDashboard>}
          />
          <Route
            path="/admindash"
            element={<AdminDashboard></AdminDashboard>}
          />
          <Route path="/forgotpass" element={<Forgotpass></Forgotpass>} />
          <Route
            path="/universityprofile"
            element={<UniversityProfile></UniversityProfile>}
          />
          <Route
            path="/deiprofile"
            element={<DEIOfficerProfile></DEIOfficerProfile>}
          ></Route>
          <Route
            path="/recruiterprofile"
            element={<RecruiterProfile></RecruiterProfile>}
          ></Route>
          <Route
            path="/candidateprofile"
            element={<CandidateProfile></CandidateProfile>}
          ></Route>
          <Route path="/jobs" element={<Jobs></Jobs>}></Route>
          <Route path="/joblisting" element={<JobListing></JobListing>}></Route>
          <Route path="/applyjobs" element={<ApplyJobs></ApplyJobs>}></Route>
          <Route
            path="/appliedjobspage"
            element={<AppliedJobsPage></AppliedJobsPage>}
          ></Route>
          <Route
            path="/viewpostingspage"
            element={<ViewPostingsPage></ViewPostingsPage>}
          ></Route>
          <Route
            path="/viewpostingspageadmin"
            element={<ViewPostingsPageAdmin></ViewPostingsPageAdmin>}
          ></Route>
          <Route
            path="/createjobspage"
            element={<CreateJobPage></CreateJobPage>}
          ></Route>
          <Route
            path="/applicationspage"
            element={<ApplicationsPage></ApplicationsPage>}
          ></Route>
          <Route
            path="/applicationspageadmin"
            element={<ApplicationsPageAdmin></ApplicationsPageAdmin>}
          ></Route>
          <Route
            path="/updateprofilepageuni"
            element={<UpdateProfilePageUni></UpdateProfilePageUni>}
          ></Route>
          <Route
            path="/viewcandidatespage"
            element={<ViewCandidatesPage></ViewCandidatesPage>}
          ></Route>
          <Route
            path="/updaterecruiterprofilepage"
            element={<UpdateRecruiterProfilePage></UpdateRecruiterProfilePage>}
          ></Route>
          <Route
            path="/updatecandidateprofilepage"
            element={<UpdateCandidateProfilePage></UpdateCandidateProfilePage>}
          ></Route>
          <Route
            path="/updatedeiprofilepage"
            element={<UpdateDEIProfilePage></UpdateDEIProfilePage>}
          ></Route>
          <Route
            path="/approveprofilespage"
            element={<ApproveProfilesPage></ApproveProfilesPage>}
          ></Route>
          <Route
            path="/deiinitiativespage"
            element={<DEIInitiativesPage></DEIInitiativesPage>}
          ></Route>
          {/* <ProtectedRoute
            path="/dashboard"
            component={RecruiterDashboard}
            isAuthenticated={isAuthenticated}
          />

          {/* Add other protected routes if needed */}
          {/* Redirect to login page if no matching routes */}
          {/* <Redirect to="/login" />  */}
        </Routes>
      </BrowserRouter>
      {/* <Login></Login> */}
      {/* <Signin></Signin> */}
      {/* <DEIOfficerDashboard name='DEI Officer Dashboard'></DEIOfficerDashboard> */}
      {/* <AcademiaDashboard name='Academia Dashboard'></AcademiaDashboard> */}
      {/* <CandidateDashboard name='Candidate Dashboard'></CandidateDashboard> */}
      {/* <Home></Home> */}
      {/* <Header name='Shreyansh'></Header> */}
      {/* <Login></Login> */}
    </div>
  );
}

export default App;
