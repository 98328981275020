import React from 'react'
import Header2 from './Header2'
import Footer from './Footer'
import './About.css'

export default function About() {
    return (
        <>
            <Header2 />

            <section className="main-section">
                    <div className="heading">
                        <h1 className="text-big" id="web">About Us</h1>
                    </div>
                <div className="box-main">
                    <br />
                    <h2>Bridging the Gap in Higher Education</h2>
                    <br />
                    <p className="text-small">
                        This is a pioneering platform dedicated to connecting universities
                        and academic institutions with talented individuals from diverse
                        backgrounds, including PhD students, postdoctoral researchers, and
                        faculty members.
                    </p>
                    <br />
                    <p className="text-small">
                        <strong>Our Mission:</strong> We believe that academia thrives
                        when it reflects the diverse perspectives and experiences of our
                        global society. Our platform is designed to support universities
                        in finding exceptional scholars from underrepresented communities
                        and creating more inclusive academic environments.
                    </p>
                    <br />
                    <h3>What We Offer:</h3>
                    <ul className="text-small">
                        <li>
                            <strong>Diverse Talent Pool:</strong> Access our extensive
                            talent pool of highly qualified individuals pursuing PhD
                            studies, postdoctoral research, and faculty roles. Find
                            candidates with diverse academic backgrounds and research
                            interests.
                        </li>
                        <li>
                            <strong>Inclusive Faculty Recruitment:</strong> Promote
                            inclusive faculty hiring practices by actively seeking
                            candidates from underrepresented groups. Embrace diversity to
                            enhance research, teaching, and the overall academic community.
                        </li>
                        <li>
                            <strong>PhD and Postdoc Opportunities:</strong> Universities can
                            post PhD and postdoctoral positions to attract diverse scholars
                            who are eager to contribute their expertise to cutting-edge
                            research projects.
                        </li>
                        <li>
                            <strong>Candidate Showcases:</strong> Enable candidates to
                            showcase their academic achievements, research contributions,
                            and teaching experience. Make informed decisions about
                            candidates who align with your institution's academic mission.
                        </li>
                        <li>
                            <strong>Equity and Diversity Analytics:</strong> Measure the
                            diversity of your academic community through our analytics
                            dashboard. Track progress and ensure your institution's
                            commitment to diversity remains strong.
                        </li>
                    </ul>
                    <br />
                    <h3>Why Choose Us:</h3>
                    <ul className="text-small">
                        <li>
                            <strong>Elevate Research Excellence:</strong> Diversity in
                            academia fosters a rich research environment that leads to
                            innovative discoveries and breakthroughs.
                        </li>
                        <li>
                            <strong>Inclusive Learning Spaces:</strong> Create inclusive
                            learning environments where students can benefit from a diverse
                            faculty and a variety of perspectives.
                        </li>
                        <li>
                            <strong>Social Impact:</strong> By hiring diverse faculty and
                            researchers, you contribute to breaking down barriers and
                            empowering underrepresented groups in academia.
                        </li>
                        <li>
                            <strong>Leadership in Diversity Initiatives:</strong>
                            Demonstrate your institution's commitment to diversity and
                            equity by actively seeking diverse candidates.
                        </li>
                    </ul>
                    <br />
                    <p className="text-small">
                        <strong>Join Us today</strong> and become a catalyst for positive
                        change in higher education. Together, we can build a more diverse
                        and inclusive academic landscape that enriches the minds and lives
                        of our students and researchers.
                    </p>
                    <br />
                    <p className="text-small">
                        <strong>Connect with Us - Register now!</strong>
                    </p>

                </div>
            </section>

            <Footer />
        </>
    )
}
