import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='primaryfooter'>
      <div className="footer">
        <p className="footer-text">Copyright &copy; All rights are reserved</p>
      </div>
    </div>
  )
}
