import './Forgotpass.css';
import { BrowserRouter, Link } from 'react-router-dom';

const Forgotpass = () => {
    return (
        <div className='primary-back'>
            <div className='navbar'>
                <div className='nav-left'>
                    <h2>URM APPLICATION</h2>
                    <img className='pfp' src={require("../../../components/images/urm.png")}></img>
                </div>
                <div className='nav-right'>
                    <Link className='navtext' to='/home'>Home</Link>
                    <Link className='navtext' to='/about'>About</Link>
                    <Link className='navtext' to='/contactus'>Contact Us</Link>
                    <Link className='navtext' to='/services'>Services</Link>
                    <a className='navtext' href='https://axm0558.uta.cloud/'>Blog</a>
                    <Link className='navtext' to='/login'>Login</Link>
                    {/* <a className='navtext' href='#'>Profile</a> */}
                    {/* <a className='navtext' href='#'>Home</a>
                    <a className='navtext' href='#'>About</a>
                    <a className='navtext' href='#'>Services</a>
                    <a className='navtext' href='#'>Contact</a>
                    <a className='navtext' href='#'>Login</a> */}
                </div>
            </div>
            <div className='sec-bar'>
                <h1>URM Application</h1>
            </div>
            <div className='partdiv'>
                <div className='partbar'>
                </div>
            </div>
            <div className='welcometext'>
                <h3>Welcome Back !</h3>
            </div>
            <div className='midinpanel'>
                <div className='forgotpanel'>
                    <div className='registerhead'>
                        <h4>Please enter your Email</h4>
                    </div>
                    <div className='top2'>
                        <input id='inputbox2' placeholder='Email' type='text'></input>
                    </div>
                    <div className='forgottext'>
                        <Link style={{ fontWeight: 600 }} to='/login'>Return to Login</Link>
                    </div>
                    <div className='top5'>
                        <button className='registerbutton'>Enter</button>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <p className='footertext'>Copyright &copy; All rights are reserved</p>
            </div>
        </div>
    )
};

export default Forgotpass;