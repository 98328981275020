import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./DEIInitiativesPage.css"; // Create this CSS file to style the page

export default function DEIInitiativesPage() {
  // Dummy data for DEI initiatives
  const deiInitiatives = [
    {
      name: "Equal Opportunities Program",
      body:
        "Our company is committed to providing equal opportunities for all employees, regardless of their background, ethnicity, gender, or any other characteristics. We strive to create an inclusive workplace where everyone can thrive.",
    },
    {
      name: "Diverse Hiring Initiatives",
      body:
        "We believe in diverse hiring practices to build a talented and inclusive workforce. Our hiring process focuses on identifying and welcoming candidates from different backgrounds and experiences.",
    },
    {
      name: "Employee Resource Groups",
      body:
        "We have established various Employee Resource Groups (ERGs) that offer a support system for employees from underrepresented communities. These groups provide networking, mentoring, and professional development opportunities.",
    },
    {
      name: "Cultural Awareness Workshops",
      body:
        "To promote cultural awareness and understanding, we conduct workshops and training sessions that educate our employees about different cultures and foster a more inclusive work environment.",
    },
    // Add more initiatives as needed
  ];

  return (
    <>
      <Header name="DEI Initiatives"></Header>
      <div className="center-container">
        <div className="dei-container">
          {deiInitiatives.map((initiative, index) => (
            <div key={index} className="dei-card">
              <h2>{initiative.name}</h2>
              <p>{initiative.body}</p>
            </div>
          ))}
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
