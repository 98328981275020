import React from "react";
import ProfileHeader from "./ProfileHeader";
import Footer from "./Footer";
import "./RecruiterProfile.css";

export default function RecruiterProfile() {
  return (
    <>
      <ProfileHeader />

      <section className="main-section">
        <div className="heading">
          <h1 className="text-big" id="web">
            Recruiter Profile
          </h1>
        </div>
        <div className="box-main">
          <div className="profile">
            <p>
              <span class="label">Recruiter Name:</span> Kelly Arora
            </p>
            <p>
              <span class="label">Agency:</span>Excel Candidate Recruiters
            </p>
            <p>
              <span class="label">List of Institutions:</span> Texas Tech
              University, Purdue University, Columbia University
            </p>
            <p>
              <span class="label">Services Offered:</span>
              URM Talent Acquisition and Recruitment, Resume Screening and
              Candidate Evaluation, Interview Coordination and Assessment,
              Diversity Hiring Strategies and Consulting
            </p>
            <p>
              <span class="label">
                <button class="btn">Edit Profile</button>
              </span>
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
