import React from 'react'
import './Header.css'

export default function ProfileHeader() {
  return (
    <>
    <div className='panel1'>
    <div className='topbar'>
      <div className='topleft'>
        <h2>URM Application</h2>
        <img className='logo' src={require("../images/urm.png")} alt="urm logo"/>
      </div>
      <div className='topright'>
        <a href='App.js'>Home</a>
        <a href='App.js'>About</a>
        <a href='App.js'>Service</a>
        <a href='App.js'>Contact Us</a>
        <a href='App.js'>Blog</a>
        <a href='App.js'>Logout</a>
      </div>
    </div>
  </div>
    </>
  )
}
