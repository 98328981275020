import React from 'react'
import Header2 from './Header2'
import Footer from './Footer'
import './Home.css'

export default function Home() {
  return (
    <>
      <Header2 />

      <section id="main-section">
        <div className="home-img">
          <img className="image1" src={require('../images/home_image.png')} alt='home_image' />
        </div>

        <section className="firstsection">
          <div className="box-main">
            <div className="firstHalf">
              <h1 className="text-big" id="web">Welcome to the URM Application!</h1>
              <br />
              <p className="text-small">
                A groundbreaking platform connecting academia with
                underrepresented minority (URM) candidates for PhD studies,
                postdoc positions, and faculty roles. Our mission is to bridge the
                diversity gap in academia by providing a comprehensive database of
                potential candidates and empowering institutions to make inclusive
                hiring decisions. Through our user-friendly dashboard, academics
                can search, filter, and connect with talented URM candidates,
                while candidates can showcase their qualifications and explore
                exciting opportunities. Join us in creating a more diverse and
                equitable academic landscape.
              </p>
            </div>
          </div>
        </section>

        <section className="secondsection">
          <div className="box-main">
            <div className="firstHalf">
              <h1 className="text-big" id="web">Who we are?</h1>

              <br />

              <p className="text-small">
                Underrepresented minority refers to a demographic group that is
                disproportionately underrepresented or lacks adequate
                representation in a particular context, such as education,
                employment, or leadership positions. These groups often face
                barriers and systemic inequalities that limit their access to
                opportunities and hinder their full participation and inclusion in
                society.
                <br />
                <br />

                This application aims to address the underrepresentation of
                minorities by connecting them with academia and employment
                opportunities, fostering inclusivity and promoting diversity.
              </p>
            </div>
          </div>
        </section>
      </section>

      <Footer />
    </>
  )
}
