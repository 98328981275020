import React, { useState, useEffect } from "react";
import "./ApproveProfilesPage.css"; // Make sure to link your CSS file here
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const ApproveProfilesPage = (props) => {
  const navigate = useNavigate();
  // State to hold user profile data
  const [profiles, setProfiles] = useState([]);

  const handleStatusButtonClick = (userId) => {
    console.log(userId);
    // Send a request to the backend API to update the status
    fetch(
      `http://nxp7046.uta.cloud/php_api/updateUserStatus.php?userId=${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Check the response to see if the update was successful
        if (data.status === "success") {
          console.log(data.message); // Log the success message (optional)
          alert("The Account has been approved Successfully!!");
          navigate("/admindash");
          setProfiles((prevProfiles) =>
            prevProfiles.map((profile) =>
              profile.UserId === userId
                ? { ...profile, Status: "Approved" }
                : profile
            )
          );
        } else {
          console.error(data.message); // Log the error message (optional)
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error); // Log any fetch errors (optional)
      });
  };

  useEffect(() => {
    const fetchJobPostings = async () => {
      try {
        let url = `http://nxp7046.uta.cloud/php_api/getUserAPI.php`;

        const response = await fetch(url);
        console.log(response);
        const data = await response.json();
        setProfiles(data);
      } catch (error) {
        console.error("Error fetching job postings:", error);
      }
    };
    // fetchAppliedJobs(userID);
    fetchJobPostings();
  }, []);

  console.log("response data : " + JSON.stringify(profiles));

  return (
    <>
      <Header name="Approve Profiles" />
      <div className="approve-profiles-page">
        <h1>User Profiles to Approve</h1>
        <table>
          <thead>
            <tr>
              {/* <th>First Name</th>
              <th>Last Name</th> */}
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {profiles.map((profile) => (
              <tr key={profile.UserId}>
                {/* <td>{profile.firstName}</td>
                <td>{profile.lastName}</td> */}
                <td>{profile.Email}</td>
                <td>{profile.UserRole}</td>
                <td>
                  <button
                    className={`status-button ${
                      profile.Status === "In Review" ? "approved" : ""
                    }`}
                    onClick={() => handleStatusButtonClick(profile.UserID)}
                    disabled={profile.Status === "Approved"} // Add disabled attribute based on status
                  >
                    {profile.Status === "Approved" ? "Approved" : "Approve"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default ApproveProfilesPage;
