import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./UpdateProfilePageUni.css"; // Make sure to link your CSS file here
import axios from "axios";

import Header from "./Header";

import Footer from "./Footer";

const UpdateDEIProfile = () => {
  const navigate = useNavigate();

  const [univData, setUserData] = useState({
    Address: "",
    Contact: "",
    FName: "",
    LName: "",
    UniversityName: "",
    Email: "",
  });

  // Function to handle form input changes
  const userID = sessionStorage.getItem("userID");
  const [initialData, setInitialData] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevUserData) => ({
      ...prevUserData,

      [name]: value,
    }));
  };

  // Function to handle form submission

  const updateData = {
    userID: sessionStorage.getItem("userID"),
    UniversityName: univData.UniversityName,
    Address: univData.Address,
    Contact: univData.Contact,

    // UniversityName: data.UniversityName ,
    // Email: data.Email,
  };
  console.log("updated : " + JSON.stringify(updateData));
  const handleSubmit = async (e, userID) => {
    e.preventDefault();

    // Your API call to update the candidate data

    // Prepare the data to be sent in the request
    axios
      .post(
        "http://nxp7046.uta.cloud/php_api/updateDEIProfileAPI.php",
        // {
        //   Personal_Statement: urmCandidateData.Personal_Statement,
        //   Address: urmCandidateData.Address,
        //   R_Experience: urmCandidateData.R_Exp,
        //   Contact: urmCandidateData.Contact,
        //   EducationLevel: urmCandidateData.Education,
        //   Nationality: urmCandidateData.Nationality,
        //   Location: urmCandidateData.Location,
        //   FieldOfStudy: urmCandidateData.FieldOfStudy,
        //   ResearchInterest: urmCandidateData.ResearchInterest,
        //   Publications: urmCandidateData.Publications,
        //   Resume: urmCandidateData.Resume,
        //   // Add any other fields that need to be updated
        // }
        updateData
      )
      .then((response) => {
        // Handle the API response here, you can show success or error messages
        console.log(response.data); // You can customize this based on your API response
        alert("Your Profile has been updated");

        navigate("/deidash");
        // Assuming the API response indicates a successful insert (you should customize this based on your API response)
        // if (response.data.success) {
        //   // Navigate to the candidatedash route
        //   navigate("/candidatedash");
        // } else {
        //   // Handle unsuccessful insert, show error message or take appropriate action
        //   console.log("Insert failed!");
        // }
      })
      .catch((error) => {
        // Handle API error here
        console.error(error);
      });
  };
  //.......

  useEffect(() => {
    const fetchCandidateData = async (userID) => {
      try {
        const response = await fetch(
          `http://nxp7046.uta.cloud/php_api/getDEIAPI.php?userID=${userID}`
        );
        const data = await response.json();
        console.log("data  : " + JSON.stringify(data));
        setUserData((prevURMCandidateData) => ({
          ...prevURMCandidateData,
          Address: data.Address ?? "Please provide your Address",
          Contact: data.Contact ?? "Please provide your Contact",
          FName: data.FName ?? "",
          LName: data.LName ?? "",
          UniversityName: data.UniversityName ?? "Provide your University",
          Email: data.Email ?? "",
        }));
        setInitialData(data); // Save the fetched data as the initialData
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchCandidateData(userID);
  }, [userID]);

  return (
    <>
      <Header name="DEI Profile Update"></Header>

      <div className="update-profile-page">
        <h1>Update Your Profile</h1>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            {/* <label>Address</label>

            <textarea
              name="address"
              value={userData.address}
              onChange={handleChange}
              required
            ></textarea> */}

            <div classname="form-group">
              <label>First Name</label>

              <input
                type="text"
                name="FName"
                value={univData.FName}
                // onChange={handleChange}
                readOnly
                required
              />
            </div>
            <div classname="form-group">
              <label>Last Name</label>

              <input
                type="text"
                name="LName"
                value={univData.LName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Email</label>

            <input
              type="email"
              name="Email"
              value={univData.Email}
              onChange={handleChange}
              readOnly
              required
            />
          </div>
          <div className="form-group">
            <label>University Name :</label>

            <input
              type="text"
              name="UniversityName"
              value={univData.UniversityName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Contact :</label>

            <input
              type="text"
              name="Contact"
              value={univData.Contact}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Address :</label>

            <input
              type="text"
              name="Address"
              value={univData.Address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <button type="submit">Update Profile</button>
          </div>
        </form>
      </div>

      <Footer></Footer>
    </>
  );
};

export default UpdateDEIProfile;
