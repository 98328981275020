import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UpdateCandidateProfilePage.css"; // Make sure to link your CSS file here
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const UpdateCandidateProfilePage = () => {
  const navigate = useNavigate();

  const [urmCandidateData, setURMCandidateData] = useState({
    Personal_Statement: "", // Initialize with an empty string
    Address: "", // Initialize with an empty string
    R_Exp: "", // Initialize with an empty string
    Contact: "", // Initialize with an empty string
    Education: "", // Initialize with an empty string
    Nationality: "", // Initialize with an empty string
    Location: "", // Initialize with an empty string
    FieldOfStudy: "", // Initialize with an empty string
    ResearchInterest: "", // Initialize with an empty string
    Publications: "", // Initialize with an empty string
    Resume: "", // Initialize with an empty string
  });

  // Rest of the component code...

  // Function to handle form input changes

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   setURMCandidateData((prevURMCandidateData) => ({
  //     ...prevURMCandidateData,

  //     [name]: value,
  //   }));
  // };

  // // Function to handle form submission

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Here you can handle the form submission, e.g., send the data to the server or perform any actions

  //   console.log(urmCandidateData);

  //   // Reset form fields after submission (optional)

  //   setURMCandidateData({
  //     firstName: "",

  //     lastName: "",

  //     email: "",

  //     contact: "",

  //     ethnicity: "",

  //     nationality: "",

  //     location: "",
  //   });
  // };
  const userID = sessionStorage.getItem("userID");
  // console.log("userID : " + userID);
  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setURMCandidateData((prevURMCandidateData) => ({
      ...prevURMCandidateData,
      [name]: value,
    }));
    setIsEdited(true); // Mark input as edited
  };

  const [initialData, setInitialData] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  // Function to handle form submission
  const updateData = {
    userID: sessionStorage.getItem("userID"),
    Personal_Statement: urmCandidateData.Personal_Statement,
    Address: urmCandidateData.Address,
    R_Experience: urmCandidateData.R_Exp,
    Contact: urmCandidateData.Contact,
    EducationLevel: urmCandidateData.Education,
    Nationality: urmCandidateData.Nationality,
    Location: urmCandidateData.Location,
    FieldOfStudy: urmCandidateData.FieldOfStudy,
    ResearchInterest: urmCandidateData.ResearchInterest,
    Publications: urmCandidateData.Publications,
    Resume: urmCandidateData.Resume,
  };
  console.log("updated : " + JSON.stringify(updateData));
  const handleSubmit = async (e, userID) => {
    e.preventDefault();

    // Your API call to update the candidate data

    // Prepare the data to be sent in the request
    axios
      .post(
        "http://nxp7046.uta.cloud/php_api/updateCandidateProfileAPI.php",
        // {
        //   Personal_Statement: urmCandidateData.Personal_Statement,
        //   Address: urmCandidateData.Address,
        //   R_Experience: urmCandidateData.R_Exp,
        //   Contact: urmCandidateData.Contact,
        //   EducationLevel: urmCandidateData.Education,
        //   Nationality: urmCandidateData.Nationality,
        //   Location: urmCandidateData.Location,
        //   FieldOfStudy: urmCandidateData.FieldOfStudy,
        //   ResearchInterest: urmCandidateData.ResearchInterest,
        //   Publications: urmCandidateData.Publications,
        //   Resume: urmCandidateData.Resume,
        //   // Add any other fields that need to be updated
        // }
        updateData
      )
      .then((response) => {
        // Handle the API response here, you can show success or error messages
        console.log(response.data); // You can customize this based on your API response
        alert("Your Profile has been updated");
        navigate("/candidatedash");
        
        // Assuming the API response indicates a successful insert (you should customize this based on your API response)
        // if (response.data.success) {
        //   // Navigate to the candidatedash route
        //   navigate("/candidatedash");
        // } else {
        //   // Handle unsuccessful insert, show error message or take appropriate action
        //   console.log("Insert failed!");
        // }
      })
      .catch((error) => {
        // Handle API error here
        console.error(error);
      });
  };
  useEffect(() => {
    const fetchCandidateData = async (userID) => {
      try {
        const response = await fetch(
          `http://nxp7046.uta.cloud/php_api/getCandidateAPI.php?userID=${userID}`
        );
        const data = await response.json();
        console.log("data  : " + JSON.stringify(data));
        setURMCandidateData((prevURMCandidateData) => ({
          ...prevURMCandidateData,
          Personal_Statement:
            data.Personal_Statement ?? "Please provide your personal statement",
          Address: data.Address ?? "Please provide your Address",
          R_Exp: data.R_Experience ?? "Please provide your Research Experience",
          Contact: data.Contact ?? "Please provide your Contact",
          Education: data.EducationLevel ?? "",
          Nationality: data.Nationality ?? "",
          Location: data.Location ?? "Please provide your Location",
          FieldOfStudy:
            data.FieldOfStudy ?? "Please provide your Field of Study",
          ResearchInterest:
            data.ResearchInterest ?? "Please provide your Interests",
          Publications: data.Publications ?? "",
          Resume: data.Resume ?? "",
        }));
        setInitialData(data); // Save the fetched data as the initialData
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    fetchCandidateData(userID);
  }, [userID]);
  console.log("urmCandidateData :" + JSON.stringify(urmCandidateData));
  const handleBlur = (e) => {
    // Check if the value was edited, if not, restore the initial value
    const { name, value } = e.target;
    if (!isEdited) {
      setURMCandidateData((prevURMCandidateData) => ({
        ...prevURMCandidateData,
        [name]: initialData[name],
      }));
    }
    setIsEdited(false); // Reset the edited state for the next interaction
  };
  return (
    <>
      <Header name="Candidate Profile Update"></Header>

      <div className="update-urm-candidate-profile-page">
        <h1>Update URM Candidate Profile</h1>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Personal Statement</label>

            <input
              type="text"
              name="Personal_Statement"
              value={urmCandidateData.Personal_Statement}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className="form-group">
            <label>Address</label>

            <input
              type="text"
              name="Address"
              value={urmCandidateData.Address}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className="form-group">
            <label>Research Experience</label>

            <input
              type="text"
              name="R_Exp"
              value={urmCandidateData.R_Exp}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className="form-group">
            <label>Contact</label>

            <input
              type="tel"
              name="Contact"
              value={urmCandidateData.Contact}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className="form-group">
            <label>Education</label>

            <input
              type="text"
              name="Education"
              value={urmCandidateData.Education}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className="form-group">
            <label>Nationality</label>

            <input
              type="text"
              name="Nationality"
              value={urmCandidateData.Nationality}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className="form-group">
            <label>Location</label>

            <input
              type="text"
              name="Location"
              value={urmCandidateData.Location}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <label>Field of Study</label>

            <input
              type="text"
              name="FieldOfStudy"
              value={urmCandidateData.FieldOfStudy}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <label>Research Interest</label>

            <input
              type="text"
              name="ResearchInterest"
              value={urmCandidateData.ResearchInterest}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <label>Publications</label>

            <input
              type="text"
              name="Publications"
              value={urmCandidateData.Publications}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>
          <div className="form-group">
            <label>Resume</label>

            <input
              type="file"
              name="Resume"
              value={urmCandidateData.Resume}
              onChange={handleChange}
              // onBlur={handleBlur}
              // required
            />
          </div>

          <div className="form-group">
            <button type="submit">Update Profile</button>
          </div>
        </form>
      </div>

      <Footer></Footer>
    </>
  );
};

export default UpdateCandidateProfilePage;

