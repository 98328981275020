import React, { useState } from "react";
import "./CreateJobPage.css"; // Make sure to link your CSS file here
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateJobPage = (props) => {
  const navigate = useNavigate();

  const [job_title, setTitle] = useState("");

  const [job_description, setDescription] = useState("");
  const [job_pay, setPayscale] = useState("");

  const [job_exp, setExperience] = useState("");
  const [job_pos, setPosition] = useState("");

  // const [jobData, setJobData] = useState({
  //   title: "",
  //   description: "",
  //   payScale: "",
  //   experience: "",
  //   position: "",
  // });

  // Function to handle form input changes
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setJobData((prevJobData) => ({
  //     ...prevJobData,
  //     [name]: value,
  //   }));
  // };

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Get the form data

    const data = {
      job_title: job_title,
      job_description: job_description,
      job_pay: job_pay,
      job_exp: job_exp,
      job_pos: job_pos,
    };
    console.log(sessionStorage.getItem("userID"));

    // Make the API call
    axios
      .post("http://nxp7046.uta.cloud/php_api/postJobAPI.php", {
        userID: sessionStorage.getItem("userID"),
        job_title: job_title,
        job_description: job_description,
        job_pay: job_pay,
        job_exp: job_exp,
        job_pos: job_pos,
      })
      .then((response) => {
        // Handle the API response here, you can show success or error messages
        console.log(response.data); 
// You can customize this based on your API response
        alert("Job has been posted Successfully!!");
        navigate("/academiadash");

        // Assuming the API response indicates a successful insert (you should customize this based on your API response)
        // if (response.data.success) {
        //   // Navigate to the candidatedash route
        //   navigate("/candidatedash");
        // } else {
        //   // Handle unsuccessful insert, show error message or take appropriate action
        //   console.log("Insert failed!");
        // }
      })
      .catch((error) => {
        // Handle API error here
        console.error(error);
      });
  };
  return (
    <>
      <Header name="Academia Dashboard"></Header>
      <div className="create-job-page">
        <h1>Create a Job</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              name="job_title"
              value={job_title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="job_description"
              value={job_description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label>Pay Scale</label>
            <input
              type="text"
              name="job_pay"
              value={job_pay}
              onChange={(e) => setPayscale(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Experience Required</label>
            <input
              type="text"
              name="job_exp"
              value={job_exp}
              onChange={(e) => setExperience(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Position</label>
            <input
              type="text"
              name="job_pos"
              value={job_pos}
              onChange={(e) => setPosition(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit">Create Job</button>
          </div>
        </form>
      </div>
      <Footer></Footer>
    </>
  );
};

export default CreateJobPage;
