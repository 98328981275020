import React, { useState, useEffect } from "react";
import "./ApplicationsPageAdmin.css"; // Make sure to link your CSS file here
import Header from "./Header";
import Footer from "./Footer";

const ApplicationsPageAdmin = (props) => {
  // // Sample candidate applications data
  // const initialApplications = [
  //   {
  //     id: 1,
  //     candidateName: "John Doe",
  //     positionApplied: "Frontend Developer",
  //     dateApplied: "2023-08-15",
  //     status: "Reviewed",
  //   },
  //   {
  //     id: 2,
  //     candidateName: "Jane Smith",
  //     positionApplied: "Backend Developer",
  //     dateApplied: "2023-08-16",
  //     status: "Pending",
  //   },
  //   // Add more candidate applications here
  // ];

  // State to hold candidate applications data
  const [applications, setApplications] = useState([]);

  // State to hold search keyword
  const [searchTerm, setSearchTerm] = useState("");
  const userID = sessionStorage.getItem("userID");

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const fetchAppliedJobs = async (userID) => {
    try {
      // Modify the URL to include the userID as a query parameter
      // const url = `http://localhost:3000/Server_Phase/API/getAllAppAPI.php`;
      const url = `http://nxp7046.uta.cloud/php_api/getApplicationALL.php?userID=${userID}`;

      const response = await fetch(url);
      const data = await response.json();
      setApplications(data);
    } catch (error) {
      console.error("Error fetching applied jobs:", error);
    }
  };
  // Fetch applied jobs data when the component mounts
  useEffect(() => {
    // Assuming you have the userID stored in a variable named 'userID'
    fetchAppliedJobs(userID);
  }, [userID]);
  console.log("applications : " + JSON.stringify(applications));

  // Function to filter candidate applications based on search term
  const filteredApplications = applications.filter((app) => {
    const FName = app.FName ? app.FName.toLowerCase() : ""; // Use FName for candidate name
    return FName.includes(searchTerm.toLowerCase());

    // app.candidateName.toLowerCase().includes(searchTerm.toLowerCase());
  });
  console.log("filteredApplications : " + JSON.stringify(filteredApplications));
  // const filteredCandidates = candidates.filter((candidate) => {
  //   // Check if job.title is defined before calling toLowerCase()
  //   const name1 = candidate.FName ? candidate.FName.toLowerCase() : ""; // Use FName for candidate name

  //   // Return the result of the filtering condition
  //   return name.includes(searchTerm.toLowerCase());
  // });

  return (
    <>
      <Header name="Applications"></Header>
      <div className="applications-page">
        <h1>Candidate Applications</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search candidates by name..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="applications">
          {filteredApplications.map((app) => (
            <div className="application" key={app.ApplicationID}>
              <p className="candidate-name">
                Name: {`${app.FName} ${app.LName}`}
              </p>

              <p className="position-applied">Job Title: {app.JobTitle}</p>
              <p className="date-applied">
                Date Applied: {app.ApplicationDate}
              </p>
              <p className="status">Status: {app.Status}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default ApplicationsPageAdmin;
