import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
import './Jobs.css';
import {  Link } from 'react-router-dom';

export default function Jobs(props) {
    return (
        <>
        <Header name = "Candidate DashBoard"></Header>
        <div class="center-container">
  <div class="rectangle-box">
    <div class="small-box">

      <a href="/update-profile">Update Profile</a>
    </div>
    <div class="small-box">
      <Link to='/applyjobs'>Apply Jobs</Link>
    </div>
    <div class="small-box">
    <Link to='/appliedjobspage'>View Jobs Applied</Link>
    </div>
    <div class="small-box">
      <span class="job-counter">10</span> Jobs Applied
    </div>
  </div>
</div>

<Footer></Footer>

        </>
    )
}
