import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./CandidateDashboard.css";
import { Link } from "react-router-dom";

export default function CandidateDashboard(props) {
  const [numApplications, setNumApplications] = useState("");
  const userID = sessionStorage.getItem("userID");
  const fetchData = (userID) => {
    fetch(
      `http://nxp7046.uta.cloud/php_api/getCandidateAppCount.php?userID=${userID}`
    ) // Assuming the API is located at '/api.php'
      .then((response) => response.json())
      .then((data) => {
        setNumApplications(data.numApplications);
        // setNumIssues(data.numIssues);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData(userID);
  }, [userID]);

  return (
    <>
      <Header name="Candidate DashBoard"></Header>
      <div class="center-container">
        <div class="rectangle-box">
          <div class="small-box">
            <a href="/updatecandidateprofilepage">Update Profile</a>
          </div>
          <div class="small-box">
            <Link to="/applyjobs">Apply Jobs</Link>
          </div>
          <div class="small-box">
            <Link to="/appliedjobspage">Jobs Applied</Link>
          </div>
          <div class="small-box">
            <span>Number of Job Applications:</span>
            <span>{numApplications}</span>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </>
  );
}
