import "./Signin.css";
import axios from "axios";
import { useState, useRef } from "react";
import validator from "validator";
import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

const Signin = () => {
  const navigate = useNavigate();
  const [candidate_firstname, setFirstName] = useState("");
  const [candidate_lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [research_experience, setRExp] = useState("");
  const [education, setEdu] = useState("");
  const [nationality, setNationality] = useState("");
  const [recruiter_firstname, setRFirstName] = useState("");

  //   const [selectedRole, setSelectedRole] = useState(null);

  const [recruiter_lastname, setRLastName] = useState("");
  const [dei_firstname, setDFirstName] = useState("");
  const [dei_lastname, setDLastName] = useState("");
  const [ethincity, setEthincity] = useState("");
  const [universityName, setUniv] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage("Strong Password");
    } else {
      setErrorMessage("Weak Password");
    }
  };

  const [candidate, setCandidate] = useState(false);
  const candidateClick = () => {
    setSelectedRole("urm-candidate");
    setCandidate(true);
    setUniversity(false);
    setDei(false);
    setRecruiter(false);
  };

  const [university, setUniversity] = useState(false);

  const universityClick = () => {
    setSelectedRole("university");
    setUniversity(true);
    setCandidate(false);
    setDei(false);
    setRecruiter(false);
  };

  const [dei, setDei] = useState(false);

  const deiClick = () => {
    setSelectedRole("dei-officer");

    setDei(true);

    setCandidate(false);

    setUniversity(false);

    setRecruiter(false);
  };

  const [recruiter, setRecruiter] = useState(false);

  const recruiterClick = () => {
    setSelectedRole("recruiter");

    setRecruiter(true);

    setCandidate(false);

    setUniversity(false);

    setDei(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();


    axios

      .post("http://nxp7046.uta.cloud/php_api/signupAPI.php", {
        role: selectedRole,

        email: email,

        password: password,

        candidate_firstname: candidate_firstname,

        candidate_lastname: candidate_lastname,

        education: education,

        research_experience: research_experience,

        nationality: nationality,

        ethincity: ethincity,

        universityName: universityName,

        recruiter_firstname: recruiter_firstname,

        recruiter_lastname: recruiter_lastname,

        dei_firstname: dei_firstname,

        dei_lastname: dei_lastname,
      })

      .then((response) => {
        // Handle the API response here, you can show success or error messages

        console.log(response.data); // You can customize this based on your API response

        navigate("/login");

        emailjs.send(
          "service_8wzw15i",
          "template_26dbgpj",
          {
            user_email: email,
            to_name: selectedRole,
          },
          "wrAQP2ZvjK5J51ZnG"
        );

        alert("Congratulation!!Your Account has been created Successfully!!");
      })

      .catch((error) => {
        // Handle API error here

        console.error(error);
      });
  };

  const [password1, showPassword] = useState(false);

  const passwordClick = () => {
    showPassword(!password);
  };

  return (
    <div className="primary-back">
      <div className="navbar">
        <div className="nav-left">
          <h2>URM APPLICATION</h2>

          <img
            className="pfp"
            src={require("../../../components/images/urm.png")}
          ></img>
        </div>

        <div className="nav-right">
          {/* <a className='navtext' href='#'>Profile</a> */}

          <Link className="navtext" to="/home">
            Home
          </Link>

          <Link className="navtext" to="/about">
            About
          </Link>

          <Link className="navtext" to="/contactus">
            Contact Us
          </Link>

          <Link className="navtext" to="/services">
            Services
          </Link>

          <a className="navtext" href="https://axm0558.uta.cloud/">
            Blog
          </a>

          <Link className="navtext" to="/login">
            Login
          </Link>

          {/* <a className='navtext' href='#'>About</a>

                    <a className='navtext' href='#'>Services</a>

                    <a className='navtext' href='#'>Contact</a>

                    <a className='navtext' href='#'>Login</a> */}
        </div>
      </div>

      <div className="sec-bar">
        <h1>URM Application</h1>
      </div>

      <div className="partdiv">
        <div className="partbar"></div>
      </div>

      <div className="welcometext">
        <h3>Welcome, Let's get started !</h3>
      </div>

      <div className="rolediv">
        <div>
          <p id="select-text">Please select one of the options:</p>
        </div>

        <div className="optionbox">
          <div
            className="role"
            onClick={candidateClick}
            value={selectedRole}
            style={{
              backgroundColor: candidate ? "lightskyblue" : "transparent",
            }}
          >
            <p className="roletext">Candidate</p>
          </div>

          <div
            className="role"
            onClick={universityClick}
            value={selectedRole}
            style={{
              backgroundColor: university ? "lightskyblue" : "transparent",
            }}
          >
            <p className="roletext">University</p>
          </div>

          <div
            className="role"
            onClick={deiClick}
            value={selectedRole}
            style={{ backgroundColor: dei ? "lightskyblue" : "transparent" }}
          >
            <p className="roletext">DEI Officer</p>
          </div>

          <div
            className="role"
            onClick={recruiterClick}
            value={selectedRole}
            style={{
              backgroundColor: recruiter ? "lightskyblue" : "transparent",
            }}
          >
            <p className="roletext">Recruiter</p>
          </div>
        </div>
      </div>

      <div className="midinpanel">
        {candidate ? (
          <div className="candidatesignin">
            <div className="registerhead">
              <h4>Create your account! It only takes a minute...</h4>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="top1">
                <input
                  id="candidate_firstname"
                  placeholder="First Name"
                  type="text"
                  value={candidate_firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                ></input>

                <input
                  id="candidate_lastname"
                  placeholder="Last Name"
                  type="text"
                  value={candidate_lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                ></input>
              </div>

              <div className="top1">
                <input
                  id="research_experience"
                  placeholder="Research Experience(please provide both years and field)"
                  type="text"
                  value={research_experience}
                  onChange={(e) => setRExp(e.target.value)}

                ></input>

                <input
                  id="education"
                  placeholder="Education"
                  type="text"
                  value={education}
                  onChange={(e) => setEdu(e.target.value)}
                  required
                ></input>
              </div>

              {/* <div className='top1'>

                            <input id='inputbox1' placeholder='Age' type='text'></input>

                            <input id='inputbox1' placeholder='Income' type='text'></input>

                        </div> */}

              <div className="top1">
                <input
                  id="nationality"
                  placeholder="Nationality:"
                  type="text"
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                  required
                ></input>

                <input
                  id="ethincity"
                  placeholder="Ethnicity: Hispanic, Latino,etc"
                  type="text"
                  value={ethincity}
                  onChange={(e) => setEthincity(e.target.value)}
                  required
                ></input>
              </div>

              <div className="top2">
                <input
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>

              <div className="top3">
                <input
                  id="password"
                  placeholder="Create a password"
                  value={password}
                  type={password ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validate(e.target.value);
                  }}
                  required
                ></input>

                <div className="eyediv">
                  <button onClick={passwordClick} className="eyebutton">
                    👁️‍🗨️
                  </button>
                </div>
              </div>

              {errorMessage === "" ? null : (
                <div className="passwordtextdiv">
                  <p className="passwordtext">{errorMessage}</p>
                </div>
              )}

              {/* <div className='top4'> */}

              {/* <p id='upload'>Upload Resume/SOP 📁</p>

                            <label className='uploadbut'>

                                <input type='file'></input>

                            </label> */}

              {/* <button className='uploadbutton'>Choose File</button> */}

              {/* </div> */}

              <div className="top5">
                <button type="submit">Register</button>
              </div>
            </form>
          </div>
        ) : null}

        {university ? (
          <div className="universitysignin">
            <div className="registerhead">
              <h4>Create your account! It only takes a minute...</h4>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="top1">
                <input
                  id="universityName"
                  value={universityName}
                  placeholder="University Name"
                  onChange={(e) => setUniv(e.target.value)}
                  type="text"
                  required
                ></input>

                {/* <input id="inputbox1" placeholder="Address" type="text"></input> */}
              </div>

              {/* <div className="top1">

                <input

                  id="inputbox1"

                  placeholder="Contact No"

                  type="text"

                ></input>

                <input

                  id="inputbox1"

                  placeholder="Location"

                  type="text"

                ></input> */}

              {/* </div> */}

              <div className="top2">
                <input
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>

              <div className="top3">
                <input
                  id="password"
                  placeholder="Create a password"
                  value={password}
                  type={password ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validate(e.target.value);
                  }}
                  required
                ></input>

                <div className="eyediv">
                  <button onClick={passwordClick} className="eyebutton">
                    👁️‍🗨️
                  </button>
                </div>
              </div>

              {errorMessage === "" ? null : (
                <div className="passwordtextdiv">
                  <p className="passwordtext">{errorMessage}</p>
                </div>
              )}

              {/* <div className='top4'>

                            <p id='upload'>Upload Resume/SOP 📁</p>

                            <label className='uploadbut'>

                                <input type='file'></input>

                            </label>

                            <button className='uploadbutton'>Choose File</button>

                        </div> */}

              <div className="top5">
                <button type="submit">Register</button>
              </div>
            </form>
          </div>
        ) : null}

        {recruiter ? (
          <div className="universitysignin">
            <div className="registerhead">
              <h4>Create your account! It only takes a minute...</h4>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="top1">
                <input
                  id="recruiter_firstname"
                  placeholder="First Name"
                  type="text"
                  value={recruiter_firstname}
                  onChange={(e) => setRFirstName(e.target.value)}
                  required
                ></input>

                <input
                  id="recruiter_lastname"
                  placeholder="Last Name"
                  type="text"
                  value={recruiter_lastname}
                  onChange={(e) => setRLastName(e.target.value)}
                  required
                ></input>
              </div>

              {/* <div className="top1">

                <input

                  id="inputbox1"

                  placeholder="Contact No"

                  type="text"

                ></input>

                <input id="inputbox1" placeholder="Agency" type="text"></input>

              </div> */}

              <div className="top2">
                <input
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>

              <div className="top3">
                <input
                  id="password"
                  placeholder="Create a password"
                  value={password}
                  type={password ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validate(e.target.value);
                  }}
                  required
                ></input>

                <div className="eyediv">
                  <button onClick={passwordClick} className="eyebutton">
                    👁️‍🗨️
                  </button>
                </div>
              </div>

              {errorMessage === "" ? null : (
                <div className="passwordtextdiv">
                  <p className="passwordtext">{errorMessage}</p>
                </div>
              )}

              {/* <div className='top4'>

                            <p id='upload'>Upload Resume/SOP 📁</p>

                            <label className='uploadbut'>

                                <input type='file'></input>

                            </label>

                            <button className='uploadbutton'>Choose File</button>

                        </div> */}

              <div className="top5">
                <button type="submit">Register</button>
              </div>
            </form>
          </div>
        ) : null}

        {dei ? (
          <div className="universitysignin">
            <div className="registerhead">
              <h4>Create your account! It only takes a minute...</h4>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="top1">
                <input
                  id="dei_firstname"
                  placeholder="First Name"
                  type="text"
                  value={dei_firstname}
                  onChange={(e) => setDFirstName(e.target.value)}
                  required
                ></input>

                <input
                  id="dei_lastname"
                  placeholder="Last Name"
                  type="text"
                  value={dei_lastname}
                  onChange={(e) => setDLastName(e.target.value)}
                ></input>
              </div>

              {/* <div className="top1">

                <input

                  id="inputbox1"

                  placeholder="Contact No"

                  type="text"

                ></input>

                <input

                  id="inputbox1"

                  placeholder="Preferences"

                  type="text"

                ></input>

              </div> */}

              <div className="top2">
                <input
                  id="email"
                  placeholder="Enter your email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>

              <div className="top3">
                <input
                  id="password"
                  placeholder="Create a password"
                  value={password}
                  type={password ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validate(e.target.value);
                  }}
                  required
                ></input>

                <div className="eyediv">
                  <button onClick={passwordClick} className="eyebutton">
                    👁️‍🗨️
                  </button>
                </div>
              </div>

              {errorMessage === "" ? null : (
                <div className="passwordtextdiv">
                  <p className="passwordtext">{errorMessage}</p>
                </div>
              )}

              {/* <div className='top4'>

                        <p id='upload'>Upload Resume/SOP 📁</p>

                        <label className='uploadbut'>

                            <input type='file'></input>

                        </label>

                        <button className='uploadbutton'>Choose File</button>

                    </div> */}

              <div className="top5">
                <button type="submit">Register</button>
              </div>
            </form>
          </div>
        ) : null}
      </div>

      <div className="footer">
        <p className="footertext">Copyright &copy; All rights are reserved</p>
      </div>
    </div>
  );
};

export default Signin;
