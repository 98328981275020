import "./Contactus.css";
import { Link, BrowserRouter , useNavigate} from "react-router-dom";
import React, { useState } from "react";

const Contactus = () => {
  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create an object with form data
    const formData = {
      fullName: fullName,
      email: email,
      description: message,
      status: "Pending", // Assuming you want to set the status to 'Pending'
    };
    console.log(JSON.stringify(formData));
    const userID = sessionStorage.getItem("userID");
    // Make the API call to insert data
    fetch(
      `http://nxp7046.uta.cloud/php_api/insetIssuesAPI.php?userID=${userID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Handle the response data if needed
        console.log(data);
        alert("Your Ticket has been Submitted!!");
        navigate("/contactus");
        
        // You can display a success message to the user or perform any other actions
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  };

  return (
    <div className="primary-back">
      <div className="navbar">
        <div className="nav-left">
          <h2>URM APPLICATION</h2>
          <img
            className="pfp"
            src={require("../../../components/images/urm.png")}
          ></img>
        </div>
        <div className="nav-right">
          {/* <a className='navtext' href='#'>Profile</a> */}
          <Link className="navtext" to={"/home"}>
            Home
          </Link>
          <Link className="navtext" to={"/about"}>
            About
          </Link>
          <Link className="navtext" to={"/services"}>
            Services
          </Link>
          <Link className="navtext" to={"/login"}>
            Login
          </Link>
          <Link className="navtext" to={"/signin"}>
            SignUp
          </Link>
          {/* <a className='navtext' href='#'>Home</a>
                    <a className='navtext' href='#'>About</a>
                    <a className='navtext' href='#'>Services</a>
                    <a className='navtext' href='#'>Login</a>
                    <a className='navtext' href='#'>SignUp</a> */}
        </div>
      </div>
      <div className="sec-bar">
        <h1>URM Application</h1>
      </div>
      <div className="partdiv">
        <div className="partbar"></div>
      </div>
      <div className="welcometext">
        <h3>
          We'd love to hear from you! You can call us at 817-XXX-XXXX or fill
          out the form below.
        </h3>
      </div>
      <div className="midinpanel">
        <div className="candidatebox">
          <div className="registerhead">
            <h4>Please enter the details</h4>
          </div>
          <div className="top2">
            <input
              id="fullName"
              placeholder="Enter your Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              type="text"
              required
            ></input>
          </div>
          <div className="top3">
            <input
              id="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type={"text"}
              required
            ></input>
          </div>
          <div className="top3">
            <input
              id="message"
              placeholder="Write Something..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type={"text"}
              required
            ></input>
          </div>
          <div className="top5">
            <button className="registerbutton" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <p className="footertext">Copyright &copy; All rights are reserved</p>
      </div>
    </div>
  );
};

export default Contactus;
