import React from 'react'
import ProfileHeader from './ProfileHeader'
import Footer from './Footer'
import './DEIOfficerProfile.css'

export default function DEIOfficerProfile() {
    return (
        <>

            <ProfileHeader />

            <section className="main-section">
                <div className="heading">
                    <h1 className="text-big" id="web">DEI Officer Profile</h1>
                </div>
                <div className="box-main">
                    <div className="profile">

                        <p>
                            <span class="label">Officer Name:</span> Harry Sykes
                        </p>
                        <p>
                            <span class="label">Skills and Expertise:</span>knowledge in diversity strategies, bias training, cultural competency, inclusive hiring practices
                        </p>
                        <p>
                            <span class="label">Achievements:</span> Recipient of the Diversity Champion Award for outstanding contributions to fostering an inclusive workplace
                        </p>
                        <p><span class="label">Publications:</span>
                            <li>
                                Author of "Building Bridges: Strategies for Inclusive Organizations"
                            </li>
                            <li>
                                Speaker at the National Conference on Diversity and Inclusion
                            </li>
                        </p>
                        <p><span class="label">Contact Information:</span> sykes.harry@example.com</p>

                        <p>
                            <span class="label"><button class="btn">Edit Profile</button></span>
                        </p>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}
