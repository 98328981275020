import React from "react";
import "./Header.css";
import { BrowserRouter, Link } from "react-router-dom";

export default function Header(props) {
  const handleLogout = () => {
    // Clear items from sessionStorage
    sessionStorage.removeItem("isLoggedIn"); // Remove the item that indicates if the user is logged in
    sessionStorage.removeItem("userID"); // Remove the item that indicates if the user is logged in
    sessionStorage.removeItem("userRole"); // Remove the item that indicates if the user is logged in
    // Add any other session items that need to be cleared
    // sessionStorage.setItem("userID", response.data.userID);
    // // sessionStorage.setItem("isLoggedIn", response.data.isLoggedIn);
    // sessionStorage.setItem("userRole", response.data.userRole);
    // You can also redirect the user to the login page after logout if needed
    // window.location.href = '/login';
  };
  return (
    <div className="panel1">
      <div className="topbar">
        <div className="topleft">
          <h2>URM Application</h2>
          <img
            className="logo"
            src={require("../../../images/urm.png")}
            alt="urm logo"
          />
        </div>
        <div className="topright">
          <Link to="/home">Profile</Link>
          <Link to="/about">About</Link>
          <Link to="/services">Services</Link>
          <Link to="/contactus">Contact Us</Link>
          <a href="https://axm0558.uta.cloud/">Blog</a>
          <Link to="/login" onClick={handleLogout}>
            Logout
          </Link>{" "}
          {/* <a href='App.js'>Profile</a>
          <a href='App.js'>Home</a>
          <a href='App.js'>About</a>
          <a href='App.js'>Service</a>
          <a href='App.js'>Contact Us</a>
          <a href='App.js'>Blog</a>
          <a href='App.js'>Logout</a> */}
        </div>
      </div>
      <div className="secbar">
        <h2>{props.name}</h2>
      </div>
      <div className="partdiv">
        <div className="partition"></div>
      </div>
    </div>
  );
}
