import React from "react";
import "./AppliedJob.css"; // Make sure to link your CSS file here
import {useNavigate } from "react-router-dom";

const AppliedJob = ({ id, title, desc, pos, status, onUpdateStatus }) => {
  const navigate = useNavigate();
  const handleApply = () => {
    // Send a request to the backend API to update the deiApproval to "Approved"
    fetch(
      `http://nxp7046.uta.cloud/php_api/updateDEIApproval.php?JobID=${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ deiApproval: "Approved" }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API returns a success message or response indicating the update was successful
        if (data.status === "success") {
          // Update the status on the frontend (optional)
          // onUpdateStatus("Approved");
          alert("Job has been Approved");
          navigate('/deidash')
        }
      })
      .catch((error) => {
        console.error("Error updating approval:", error);
      });
  };

  const role = sessionStorage.getItem("userRole");
  return (
    <div className="applied-job">
      <h2 className="job-title">{title}</h2>
      <p className="job-id" hidden>
        ID: {id}
      </p>

      <p className="job-info">Description: {desc}</p>
      <p className="job-info">Position: {pos}</p>
      <p className="job-info">Status: {status}</p>

      {role === "DEI_Officer" && <button onClick={handleApply}>Approve</button>}
    </div>
  );
};

export default AppliedJob;
//onClick={handleApply}
