import React, { useState, useEffect } from "react";

const ViewIssues = () => {
  const [issues, setIssues] = useState([]);

  // Function to fetch issues data from the server
  const fetchIssues = () => {
    fetch("http://nxp7046.uta.cloud/php_api/viewIssues.php")
      .then((response) => response.json())
      .then((data) => {
        setIssues(data);
      })
      .catch((error) => {
        console.error("Error fetching issues:", error);
      });
  };

  useEffect(() => {
    // Fetch issues data when the component mounts
    fetchIssues();
  }, []);

  // Send a request to the API to update the status to "Solved"
  const handleSolveClick = (ticketID) => {
    fetch(
      `http://nxp7046.uta.cloud/php_api/updateIssueStatus.php?ticketID=${ticketID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status: "Solved" }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // If the update was successful, fetch the updated issues data again
        fetchIssues();
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  return (
    <div>
      <h1>View Issues</h1>
      <table>
        <thead>
          <tr>
            <th>Ticket ID</th>
            <th>User ID</th>
            <th>Description</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {issues.map((issue) => (
            <tr key={issue.TicketID}>
              <td>{issue.TicketID}</td>
              <td>{issue.UserID}</td>
              <td>{issue.Description}</td>
              <td>{issue.Status}</td>
              {issue.Status === "Pending" && (
                <td>
                  <button onClick={() => handleSolveClick(issue.TicketID)}>
                    Solve
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewIssues;
