import React, { useState, useEffect } from "react";
import Header from "./Header";
import JobListing from "./JobListing";
import Footer from "./Footer";
import "./ApplyJobs.css";

export default function ApplyJobs(props) {
  // Sample job data
  // const initialJobs = [
  //   {
  //     id: 1,
  //     title: "Software Engineer",
  //     location: "San Francisco, CA",
  //     salary: "$100,000",
  //     position: "Full-time",
  //   },
  //   {
  //     id: 2,
  //     title: "Web Developer",
  //     location: "New York, NY",
  //     salary: "$90,000",
  //     position: "Part-time",
  //   },
  //   // Add more job listings here
  // ];

  const [filteredJobs, setJobPostings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchJobPostings = async () => {
      try {
        let url = "http://nxp7046.uta.cloud/php_api/getJobsAPI.php";
        if (searchTerm) {
          url += `?searchQuery=${encodeURIComponent(searchTerm)}`;
        }
        const response = await fetch(url);
        console.log(response);
        const data = await response.json();
        setJobPostings(data);
      } catch (error) {
        console.error("Error fetching job postings:", error);
      }
    };

    fetchJobPostings();
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Header></Header>

      <div className="app-container">
        <h1>Job Listings</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="job-listings">
          {filteredJobs.map((job) => (
            <JobListing
              key={job.JobID}
              id={job.JobID}
              title={job.JobTitle}
              desc={job.Description}
              salary={job.PayScale}
              pos={job.Position}
              // onApply={() => handleApply(job.id)} // Add your handleApply logic here
            />
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
