import "./JobListing.css";
import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom"; 

const JobListing = ({ id, title, desc, salary, pos, onApply }) => {
  const navigate = useNavigate();
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    // Fetch application data from the server to check if the job is applied
    const checkApplicationStatus = async () => {
      try {
        const response = await fetch(
          "http://nxp7046.uta.cloud/php_api/checkApplicationAPI.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              JobID: id,
              userID: sessionStorage.getItem("userID"),
            }),
          }
        );

        const data = await response.json();
        setIsApplied(data.isApplied);
      } catch (error) {
        console.error("Error checking application status:", error);
      }
    };

    checkApplicationStatus();
  }, [id]);
  const handleApply = async () => {
    try {
      const response = await fetch(
        "http://nxp7046.uta.cloud/php_api/insertApplicationAPI.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            JobID: id,
            userID: sessionStorage.getItem("userID"),
          }), // Assuming pos contains the JobID
        }
      );

      const data = await response.json();
      if (response.ok) {
        setIsApplied(true);
        alert(data.message); // Application successful
        // alert("Job has been posted Successfully!!");
        navigate("/applyjobs");
      } else {
        alert(data.error); // Application failed or any other error message
      }
    } catch (error) {
      console.error("Error applying for job:", error);
    }
  };
  return (
    <div className="job-listing">
      <h2 className="job-title" hidden>
        {id}
      </h2>
      <h2 className="job-title">{title}</h2>
      <p className="job-info">Description: {desc}</p>
      <p className="job-info">Salary: {salary}</p>
      <p className="job-info">Position: {pos}</p>
      {isApplied ? (
        <button className="applied-button" disabled>
          Applied
        </button>
      ) : (
        <button className="apply-button" onClick={handleApply}>
          Apply
        </button>
      )}
    </div>
  );
};

export default JobListing;
