import React from 'react'
import ProfileHeader from './ProfileHeader'
import Footer from './Footer'
import './CandidateProfile.css'

export default function CandidateProfile() {
    return (
        <>

            <ProfileHeader />

            <section className="main-section">
                <div className="heading">
                    <h1 className="text-big" id="web">Candidate Profile</h1>
                </div>
                <div className="box-main">
                    <div className="profile">

                        <p>
                            <span class="label">Candidate Name:</span> Will Smith
                        </p>
                        <p>
                            <span class="label">Education:</span>PhD in Computer Science
                        </p>
                        <p>
                            <span class="label">Research Experience:</span> 5 years
                        </p>
                        <p><span class="label">Personal Statement:</span> I am driven to make a positive impact through my work. I believe that diversity in technology is crucial for innovation and problem-solving. My research focuses on leveraging artificial intelligence to address social challenges and promote equity. I am dedicated to contributing to inclusive research, mentoring underrepresented students, and bridging the gap in computer science. Together, we can create a more diverse and inclusive future for technology.</p>
                        <p><span class="label">Contact Information:</span> mith.will32@example.com</p>
                        <p><span className="label">Resume/CV:</span> <a href="Resume_Will_Smith.pdf" style={{ cursor: 'pointer' }}>Resume_Will_Smith.pdf</a><img src={require("../images/file_upload.jpg")} alt='upload' style={{ paddingLeft: '6px', height: '12px', width: '12px' }} /></p>
                            <p><span className="label">Cover Letter:</span> <a href="Cover_Letter.pdf" style={{ cursor: 'pointer' }}>Cover_Letter.pdf</a><img src={require("../images/file_upload.jpg")} alt='upload' style={{ paddingLeft: '6px', height: '12px', width: '12px' }} /></p>
                            <p><span className="label">Academic Record:</span> <a href="Transcript.pdf" style={{ cursor: 'pointer' }}>Transcript.pdf</a><img src={require("../images/file_upload.jpg")} alt='upload' style={{ paddingLeft: '6px', height: '12px', width: '12px' }} /></p>
                        <p>
                            <span class="label"><button class="btn">Edit Profile</button></span>
                        </p>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}
