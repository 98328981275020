import React from 'react'
import './Services.css'
import Header2 from './Header2'
import Footer from './Footer'
import { Link, BrowserRouter } from 'react-router-dom'

export default function Services() {
    return (
        <>
            <Header2 />

            <section className="main-section">
                <div className="page-heading">
                    <h1>SERVICES</h1>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="firstPanel">
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> URM Candidates</b></h4>
                                </div>
                            </div>
                        </a>
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> Institutions</b></h4>
                                </div>
                            </div>
                        </a>
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> Job postings</b></h4>
                                </div>
                            </div>
                        </a>

                    </div>
                    <div className="firstPanel">
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> Recruiter Dashboard</b></h4>
                                </div>
                            </div>
                        </a>
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> Academia Dashboard</b></h4>
                                </div>
                            </div>
                        </a>
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> DEI Officer Dashboard</b></h4>
                                </div>
                            </div>
                        </a>

                    </div>
                    <div className="secondPanel">
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> DEI SignUp</b></h4>
                                </div>
                            </div>
                        </a>
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> University SignUp</b></h4>
                                </div>
                            </div>
                        </a>
                        <a href="RecruiterDashboard.html">
                            <div className="card">
                                <div className="services-content">
                                    <h4><b> Recruiter SignUp</b></h4>
                                </div>
                            </div>
                        </a>

                    </div>
                    <br />
                </div>
            </section>

            <Footer />

        </>
    )
}
