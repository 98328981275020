import React from 'react'
import ProfileHeader from './ProfileHeader'
import Footer from './Footer'
import './UniversityProfile.css'

export default function UniversityProfile() {
    return (
        <>

            <ProfileHeader />

            <section className="main-section">
                <div className="heading">
                    <h1 className="text-big" id="web">University Profile</h1>
                </div>
                <div className="box-main">
                    <div className="profile">

                    <p>
                        <span class="label">University Name:</span> University Of Texas At
                        Arlington
                    </p>
                    <p>
                        <span class="label">Address:</span>701 S Nedderman Dr, Arlington, TX
                        76019
                    </p>
                    <p>
                        <span class="label">Research Areas:</span> Artificial intelligence,
                        Machine Learning, Big Data
                    </p>
                    <p><span class="label">Number of Faculty:</span> 56</p>
                    <p><span class="label">Contact Information:</span> +1(879)-654-3271</p>
                    <p>
                        <span class="label"><button class="btn">Edit Profile</button></span>
                    </p>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}
