import React from 'react'
import './Header.css'
import { BrowserRouter, Link } from 'react-router-dom'

export default function Header2() {
  return (
    <>
      <div className='panel1'>
        <div className='topbar'>
          <div className='topleft'>
            <h2>URM Application</h2>
            <img className='logo' src={require("../images/urm.png")} alt="urm logo" />
          </div>
          <div className='topright'>
            <Link to='/home'>Profile</Link>
            <Link to='/about'>About</Link>
            <Link to='/services'>Services</Link>
            <Link to='/contactus'>Contact Us</Link>
            <a href='https://axm0558.uta.cloud/'>Blog</a>
            <Link to='/signin'>Logout</Link>
            {/* <a href='App.js'>Profile</a>
            <a href='App.js'>Home</a>
            <a href='App.js'>About</a>
            <a href='App.js'>Service</a>
            <a href='App.js'>Contact Us</a>
            <a href='App.js'>Blog</a>
            <a href='App.js'>Logout</a> */}
          </div>
        </div>
      </div>
    </>
  )
}
