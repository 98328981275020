import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./AdminDashboard.css"; // Make sure to link your CSS file here
import { Link } from "react-router-dom";

// import CanvasJSReact from "@canvasjs/react-charts";

 

import {

  Chart as ChartJS,

  BarElement,

  CategoryScale,

  LinearScale,

  Tooltip,

  Legend,

} from "chart.js"; // Import the Bar chart component

import { Bar } from "react-chartjs-2";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

// import "react-chartjs-2/dist/react-chartjs-2.css"; // Import the CSS file

 

function AdminDashboard(props) {

  // State variables to hold the counts

  const [numCandidates, setNumCandidates] = useState("");

  const [numPostings, setNumPostings] = useState("");

  const [numApplications, setNumApplications] = useState("");

  // const [numIssues, setNumIssues] = useState("");

  const chartRef = useRef(null); // Reference to the Chart instance

 

  // Function to fetch data from the API and set the counts in the state

  const fetchData = () => {

    fetch("http://nxp7046.uta.cloud/php_api/statsAPI.php") // Assuming the API is located at '/api.php'

      .then((response) => response.json())

      .then((data) => {

        setNumCandidates(data.numCandidates);

        setNumPostings(data.numPostings);

        setNumApplications(data.numApplications);

        // setNumIssues(data.numIssues);

      })

      .catch((error) => {

        console.error("Error fetching data:", error);

      });

  };

 

  // Fetch data when the component mounts

  useEffect(() => {

    fetchData();

  }, []);

  // Sample data for the dashboard

  // const numCandidates = 500; // Change this value as needed

  // const numPostings = 50; // Change this value as needed

  // const numApplications = 200; // Change this value as needed

  // const numIssues = 10; // Change this value as needed

  useEffect(() => {

    // Update the chart data whenever the state variables change

    updateChartData();

  }, [numCandidates, numPostings, numApplications]);

 

  const updateChartData = () => {

    if (chartRef.current) {

      // If a chart instance exists, destroy it

      chartRef.current.destroy();

    }

  };

 

  // Create new chart data

  const chartData = {

    labels: ["Candidates", "Postings", "Applications"],

    datasets: [

      {

        label: "Counts",

        backgroundColor: ["aqua", "Blue", "Red"],

        borderColor: [

          "rgba(255, 99, 132, 1)",

          "rgba(54, 162, 235, 1)",

          "rgba(75, 192, 192, 1)",

        ],

        borderWidth: 1,

        data: [numCandidates, numPostings, numApplications],

      },

    ],

  };

 

  // Options for the bar chart

  const options = {

    responsive: true,

    maintainAspectRatio: false,

    scales: {

      y: {

        beginAtZero: true,

        ticks: {

          precision: 0,

        },

      },

    },

  };

 

  // Create a new chart instance and store the reference

 

  return (

    <>

      <Header name="Admin Dashboard" />

      <div className="center-container">

        <div className="dashboard-container">

          <div className="big-rectangle-box">

            {/* Small boxes inside the big rectangle */}

            <div className="small-box">

              <Link to="/viewcandidatespage">View Candidates</Link>

            </div>

            <div className="small-box">

              <Link to="/viewpostingspageadmin">View Job Postings</Link>

            </div>

            <div className="small-box">

              <Link to="/applicationspageadmin">View Applications</Link>

            </div>

            <div className="small-box">

              <Link to="/approveprofilespage">Approve Users</Link>

            </div>

            <div className="small-box">

              <Link to="/viewissues">View Issues</Link>

            </div>

          </div>

        </div>

        <div className="dashboard-container">

          {/* Right box - Stats boxes */}

          <div className="stats-boxes">

            <div className="stats-item">

              <span>Number of Candidates:</span>

              <span>{numCandidates}</span>

            </div>

            <div className="stats-item">

              <span>Number of Job Postings:</span>

              <span>{numPostings}</span>

            </div>

            <div className="stats-item">

              <span>Number of Applications Received:</span>

              <span>{numApplications}</span>

            </div>

 

            {/* This is where the Chart will be rendered */}

            {/* <div className="stats-item">

              <span>Number of Issues:</span>

              <span>{numIssues}</span>

            </div> */}

          </div>

          <div className="stats-graph">

            <Bar data={chartData} option={options}></Bar>

          </div>

        </div>

      </div>

      <Footer />

    </>

  );

}

export default AdminDashboard;

 