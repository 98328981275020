import React, { useState, useEffect } from "react";
import "./ViewCandidatesPage.css"; // Make sure to link your CSS file here
import Header from "./Header";
import Footer from "./Footer";

const ViewCandidatesPage = (props) => {
  // Sample candidate data
  // const initialCandidates = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     educationLevel: "Master's Degree",
  //     interests: "Web Development, Data Science",
  //     experienceYears: 3,
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     educationLevel: "Bachelor's Degree",
  //     interests: "Machine Learning, Artificial Intelligence",
  //     experienceYears: 5,
  //   },
  //   // Add more candidate data here
  // ];

  // State to hold candidate data
  const [candidates, setCandidates] = useState([]);

  // State to hold search keyword
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Function to filter candidates based on search term
  // const filteredCandidates = candidates.filter((candidate) =>
  //   candidate.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  // Function to filter applied jobs based on search term
  const filteredCandidates = candidates.filter((candidate) => {
    // Check if job.title is defined before calling toLowerCase()
    const name = candidate.FName ? candidate.FName.toLowerCase() : ""; // Use FName for candidate name

    // Return the result of the filtering condition
    return name.includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    // Fetch candidates data from the API and update the state
    const getCandidatesAPI = async () => {
      try {
        const response = await fetch(
          "http://nxp7046.uta.cloud/php_api/getCandidatesALL.php"
        ); // Point to your PHP script
        if (!response.ok) {
          throw new Error("Failed to fetch candidates data");
        }
        const data = await response.json();
        setCandidates(data); // Update candidates state with the fetched data
      } catch (error) {
        console.error(error);
      }
    };

    getCandidatesAPI(); // Call the getCandidatesAPI function when the component mounts
  }, []);
  console.log("Candidates : " + JSON.stringify(candidates));
  console.log("filteredCandidates : " + filteredCandidates);
  return (
    <>
      <Header name="View Candidates"></Header>
      <div className="view-candidates-page">
        <h1>Candidates Information</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search candidates by name..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="candidates">
          {filteredCandidates.map((candidate) => (
            <div className="candidate" key={candidate.CandidateID}>
              <p className="candidate-name">{candidate.FName}</p>{" "}
              {/* Use FName for candidate name */}
              <p className="education-level">
                Education Level: {candidate.EducationLevel}
              </p>
              <p className="interests">
                Interests: {candidate.ResearchInterest}
              </p>{" "}
              {/* Update this to the correct property name if needed */}
              <p className="experience">
                Experience (years): {candidate.R_Experience}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default ViewCandidatesPage;
