import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./AcademiaDashboard.css"; // Make sure to link your CSS file here
import { Link } from "react-router-dom";

export default function AcademiaDashboard(props) {
  // Sample data for stats
  const [numApplications, setNumApplications] = useState("");
  const [numPostings, setNumPostings] = useState("");

  const userID = sessionStorage.getItem("userID");
  const fetchData = (userID) => {
    fetch(
      `http://nxp7046.uta.cloud/php_api/getUnivStats.php?userID=${userID}`
    ) // Assuming the API is located at '/api.php'
      .then((response) => response.json())
      .then((data) => {
        setNumApplications(data.numApplications);
        setNumPostings(data.numPostings);
        // setNumIssues(data.numIssues);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    fetchData(userID);
  }, [userID]);
  return (
    <>
      <Header name="Academia Dashboard"></Header>
      <div className="center-container">
        <div className="dashboard-container">
          <div className="big-rectangle-box">
            {/* Small boxes inside the big rectangle */}
            <div className="small-box">
              <Link to="/viewpostingspage">View Postings</Link>
            </div>
            <div className="small-box">
              <Link to="/updateprofilepageuni">View / Update Profile</Link>
            </div>
            <div className="small-box">
              <Link to="/createjobspage">Create Job</Link>
            </div>
            <div className="small-box">
              <Link to="/viewcandidatespage">View Candidates</Link>
            </div>
            <div className="small-box">
              <Link to="/applicationspage">View Applications</Link>
            </div>
          </div>
        </div>
        <div className="dashboard-container">
          {/* Right box - Stats boxes */}
          <div className="stats-boxes">
            <div className="stats-item">
              <span>Number of Postings:</span>
              <span>{numPostings}</span>
            </div>
            <div className="stats-item">
              <span>Number of Applications Received:</span>
              <span>{numApplications}</span>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
