import React, { useState, useEffect } from "react";

import AppliedJob from "./AppliedJob";
import "./AppliedJobsPage.css"; // Make sure to link your CSS file here
import Header from "./Header";
import Footer from "./Footer";

const AppliedJobsPage = (props) => {
  // Sample applied jobs data
  // const initialAppliedJobs = [
  //   {
  //     id: 1,
  //     title: "Frontend Developer",
  //     location: "Los Angeles, CA",
  //     status: "In Review",
  //   },
  //   {
  //     id: 2,
  //     title: "Backend Developer",
  //     location: "Chicago, IL",
  //     status: "In Review",
  //   },
  //   // Add more applied job listings here
  // ];

  // State to hold applied jobs data
  // State to hold applied jobs data
  const [appliedJobs, setAppliedJobs] = useState([]);

  // State to hold search keyword
  const [searchTerm, setSearchTerm] = useState("");

  // Function to handle search input change

  // Function to fetch applied jobs data from the server
  const fetchAppliedJobs = async (userID) => {
    try {
      // Modify the URL to include the userID as a query parameter
      const url = `http://nxp7046.uta.cloud/php_api/getAppliedJobsAPI.php?userID=${userID}`;

      const response = await fetch(url);
      const data = await response.json();
      setAppliedJobs(data);
    } catch (error) {
      console.error("Error fetching applied jobs:", error);
    }
  };
  const userID = sessionStorage.getItem("userID");
  // Fetch applied jobs data when the component mounts
  useEffect(() => {
    // Assuming you have the userID stored in a variable named 'userID'
    fetchAppliedJobs(userID);
  }, [userID]);
  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Function to filter applied jobs based on search term
  const filteredAppliedJobs = appliedJobs.filter((job) => {
    // Check if job.title is defined before calling toLowerCase()
    const title = job.title ? job.title.toLowerCase() : "";

    // Check if title includes the search term (also converted to lowercase)
    return title.includes(searchTerm.toLowerCase());
  });

  return (
    <>
      <Header name="Candidate Dashboard"></Header>
      <div className="applied-jobs-page">
        <h1>Applied Jobs</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search jobs by title..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <div className="applied-jobs">
          {filteredAppliedJobs.map((job) => (
            <AppliedJob
              key={job.JobID}
              title={job.JobTitle}
              desc={job.Description}
              pos={job.Position}
              status={job.Status}
              //     onUpdateStatus={() => handleUpdateStatus(job.id)}
            />
          ))}
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default AppliedJobsPage;
